.wrapper {
  height: 100vh;
  width: 100vw;
  z-index: 10;
  display: block;
  position: fixed;
  overflow: scroll;
  background: #2d2d35;
}

.canvas {
  overflow: hidden;
  z-index: -100 !important;
  position: fixed;
  background: #1f1f23;
}

.second-session {
  height: 100vh;
  z-index: 100;
}